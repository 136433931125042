import moment from 'moment/moment.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'
import RangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'
import {German} from 'flatpickr/dist/l10n/de.js'


$(function () {

    $('.datetimepicker').each(function () {
        let picker = $(this)
        let options = {
            enableTime: true,
            dateFormat: 'd.m.Y H:i',
            time_24hr: true,
            locale: German,

            onChange: function (selectedDates, dateStr, instance) {
                let sourceName = instance.element.name
                let name = sourceName.substring(0, sourceName.length)
                let hidden = $('input[is-real-input="true"][name="' + name + '"]:hidden')
                //There are 2 selected dates, seems to be an range picker, must set the belonging
                if (selectedDates.length === 2) {
                    let hidden2 = $('input[is-real-input="true"][name="' + $(instance.element).attr('range-to') + '"]:hidden')
                    hidden2.val(moment(selectedDates[1]).format('YYYY-MM-DD HH:mm:SS'))
                }

                if (dateStr.length > 0) {
                    hidden.val(moment(selectedDates[0]).format('YYYY-MM-DD HH:mm:SS'))
                } else {
                    hidden.val('')
                }

            },
            onReady: function (selectedDates, dateStr, instance) {
                let sourceName = instance.element.name
                let name = sourceName.substring(0, sourceName.length)
                let hidden = $('input[is-real-input="true"][name="' + name + '"]:hidden')
                instance.setDate(hidden.val(), false, 'Y-m-d H:i:s')
            }
        }

        if (this.hasAttribute('data-min-date')) {
            options.minDate = picker.attr('data-min-date');
        }

        if (this.hasAttribute('range-to')) {
            options.plugins = [
                new ConfirmDatePlugin({
                    confirmIcon: '<i class="fa fa-check"></i>',
                    showAlways: true,
                }),
                new RangePlugin({
                    input: 'input[name=\'' + picker.attr('range-to') + '_d\']'
                })
            ]
        } else {
            options.plugins = [new ConfirmDatePlugin({
                confirmIcon: '<i class="fa fa-check"></i>',
                showAlways: true,
            })]
        }
        picker.flatpickr(options)

    })

})
